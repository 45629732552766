h3 {
  color: #fff;
  text-align: center;
  margin-top: 5px;
}

h2 {
  font-weight: bold !important;
}

h1 {
  text-align: left;
}

.col {
  font-weight: bold;
}

.logoIndex {
  text-align: center;
}

.divEnderecoConfirm label {
  cursor: pointer;
  border: none;
  color: #ed6d11;
  background-color: transparent;
  width: 100%;
  margin-left: 14px;
  font-size: 11px;
  margin-top: 5px;
  font-weight: bold;
}

.divEnderecoConfirm input {
  display: block;
  cursor: pointer;
  border: none;
  padding: 5px 15px 4px 13px;
  width: 95%;
  background-color: transparent;
}

.divEnderecoConfirm {
  cursor: pointer;
  border: 1px solid #ed6d11;
  border-radius: 10px;
  margin-bottom: 10px;
  background-color: white;
  text-align: left;
}

.divEndereco label {
  display: none;
}

.divEndereco input {
  cursor: pointer;
  border: none;
  padding: 11px;
  background-color: transparent;
  width: 100%;
}

.divEndereco {
  cursor: pointer;
  border: 1px solid #333;
  border-radius: 10px;
  border-color: rgb(119, 118, 118);
  margin-bottom: 10px;
  height: 44px;
  background-color: white;
}

.dddInput {
  width: 80%;
}

.inputCelular {
  width: calc(100% - 80px) !important;
  float: right;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.regular-checkbox {
  -webkit-appearance: none;
  background-color: #fafafa;
  border: 1px solid #999;
  padding: 9px;
  border-radius: 10px;
  display: inline-block;
  position: relative;
  margin-left: 0px;
}

.regular-checkbox:checked {
  background-color: #fff;
  border: 1px solid #ed6d11;
  color: #99a1a7;
}

.regular-checkbox:checked:after {
  content: "\2714";
  position: absolute;
  top: 0px;
  color: green;
}

.big-checkbox {
  padding: 12px;
}

.big-checkbox:checked:after {
  font-size: 24px;
  left: 3px;
  margin-top: -5px;
}

.desejaReceber {
  text-align: left;
  padding-left: 3px;
  margin-top: -4px;
}

.col-12 {
  width: 100%;
  display: table;
}

.col-12 span {
  display: table-cell;
  vertical-align: middle;
}

.btn_agendamento {
  margin-top: 20px;
  width: 100%;
  border: none;
  border-radius: 10px;
  padding: 10px;
  background-color: #ed6d11;
  color: aliceblue;
  font-weight: bold;
  font-size: 14px;
  height: 50px;
}

.img_agendamento {
  width: 100px;
  padding-bottom: 30px;
  text-align: center;
}

.inputDdd_agendamento {
  width: 65px !important;
  float: left;
}

.inputCelular_agendamento {
  width: calc(100% - 80px) !important;
  float: right;
}

:focus-visible {
  outline: none !important;
  width: 90%;
}

.pointer {
  cursor: pointer;
}

.faixaHeader {
  width: 100%;
  margin: 0;
  background: #fff;
  box-shadow: 0 5px 10px rgb(0 0 0 / 15%);
  padding-top: 5px;
  position: sticky;
  height: 50px;
}

.faixaHeader img {
  padding: 0px !important;
}

.container {
  max-width: 700px;
  margin-top: 0px;
  padding: 10px;
}

#t1n0, #t2n0, #t1n1, #t2n1, #t1n2, #t2n2, #t1n3, #t2n3, #t1n4, #t2n4, #t1n5, #t2n5, #t1n6, #t2n6 {
  background-color: #d6463c;
}

#t1n7, #t2n7, #t1n8, #t2n8 {
  background-color: #EEE62A;
}

#t1n9, #t2n9, #t1n10, #t2n10 {
  background-color: #29ba7e;
}

body {
  background-color: #fff;
  padding: 0 10px;
  max-width: 700px;
}

td {
  color: #fff;
  border: 1px solid #fff;
  height: 32px;
  border-radius: 5px;
}

.container {
  max-width: 500px;
  padding: 4px;
}

.textoTop {
  text-align: left;
}

.inputComentario {
  color: #000;
  width: 100%;
  border-radius: 7px;
}

.labelComentario {
  text-align: left;
}

.bemvindo {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 120%;
}

.subTituloTop {
  color: #5F5C60;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 30px;
  line-height: 140%;
  margin-top: 5px;
}

.btnConfirmar {
  width: 100%;
  height: 55px;
  background-color: #22259F;
  color: white;
  font-size: 14px;
  border: none;
  border-radius: 10px;
}

.btnConfirmar:disabled {
  width: 100%;
  height: 55px;
  background-color: #5F5C60;
  color: white;
  font-size: 14px;
  border: none;
  border-radius: 10px;
}

.navbar {
  color: #ffffff;
  z-index: 999;
  background: #fff;
  width: 100%;
  text-align: center;
  border-bottom: 2px solid #D9D8D9;
  max-width: 700px;
  margin-bottom: 20px;
  margin-top: -16px;
}

.logoMMSResposta {
  width: 130px;
  margin-top: 5px;
  margin-top: 115px;
}


.logoMMS {
  width: 80px;
  margin-top: 5px;
  margin-bottom: 9px;
}

html {
  background-color: gainsboro;
  font-family: 'Source Sans Pro';
}

.divComentario {
  width: 100%;
}

.rec {
  display: flow-root;
  margin-bottom: 30px;
}

table {
  margin-left: -2px;
  margin-top: 3px;
}

.txtOpiniao {
  text-align: center;
  font-size: 15px;
  color: gray;
}

.p-grid {
  display: initial;
}

.txtResposta {
  font-size: 20px;
  margin-top: -40px;
}